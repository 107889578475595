import React, { useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #ffffff;
  @media (max-width: 768px) {
 
  }
`;

// Estilo para el contenedor del widget que añade un margen blanco en la parte inferior
const WidgetContainer = styled.div`
  position: relative;
  height: 360px;
 // Establece esto a la altura deseada que muestre el contenido que quieres pero oculte el mensaje "Free Google Reviews"
  overflow: hidden;

  @media (max-width: 768px) {
    height: 340px;
}
`;


const Reseñas = () => {
  useEffect(() => {
    // Crea el script de Elfsight
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.setAttribute('data-use-service-core', '');
    script.defer = true;

    // Añade el script al body del documento
    document.body.appendChild(script);

    // Opcional: Limpieza al desmontar el componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container>
   
      <WidgetContainer>

       
       {/* <div className="elfsight-app-7e044b28-814d-4d7f-b749-bb027dbd8d62" data-elfsight-app-lazy></div> */}
       

         {/* <div className="elfsight-app-1b2bc190-19ed-497b-8282-aade676a2fe0" data-elfsight-app-lazy></div>  */}


        <div className="elfsight-app-f5389df8-a2a8-4546-8045-a2b75af6cccb" data-elfsight-app-lazy></div> 
        
      </WidgetContainer>
    </Container>
  );
}

export default Reseñas;
